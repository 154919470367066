import React from "react";
import {graphql, Link} from "gatsby";
import Layout from '../components/Layout'
import Seo from "../components/Seo";
import {GatsbyImage} from "gatsby-plugin-image";
import {Fade} from "react-awesome-reveal";
import Icon from "../components/elements/Icon";
import BG from '../assets/svgs/background.svg';

const BlogOverview = ({data}) => {
    const {wpPage: page, allWpPost} = data;

    function renderBg() {
        return (
            <div className="text-center">
                <strong className="page-heading block">{page.title}</strong>
            </div>
        )
    }

    function renderImage(post) {
        if (!post.featuredImage) {
            return <BG height={128}  />;
        }

        return (
            <GatsbyImage alt={post.title}
                         placeholder="blurred"
                         imgClassName="w-full rounded-full border-2 group-hover:border-primary"
                         className="w-32 h-32"
                         imgStyle={{objectFit: "cover"}}
                         loading="lazy"
                         image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}>
            </GatsbyImage>
        )
    }

    function renderBlogs() {
        return allWpPost.edges.map((node, index) => {
            const post = node.node;
            const url = '/' + post.slug;
            const content = post.content.replace(/<[^>]+>/g, '').substr(0, 100).concat('...');
            const featuredImage = renderImage(post);
            const tags = Object.values(post.tags.nodes).map(e => e.name);

            return (
                <Fade triggerOnce direction="up" className="p-12 md:w-1/2 flex flex-col items-start" key={url}>
                    <Link to={url} className="group transition-colors">
                        <div className="lg:flex text-center lg:text-left ">
                            <div className="relative mb-3 lg:mb-0">
                                {featuredImage}
                            </div>
                            <div className="ml-4">
                                <h2 className="sm:text-xl text-2xl title-font font-medium text-gray-900">
                                    {post.title}
                                </h2>
                                <span className="block mb-3 py-1 rounded bg-indigo-50 text-indigo-500 text-xs font-medium tracking-widest">
                                     {post.date}
                                 </span>
                                <p className="leading-relaxed mb-8" dangerouslySetInnerHTML={{__html: content}}></p>
                                <div
                                    className="flex items-center flex-wrap pb-4 border-b-2 border-gray-100 mt-auto w-full">
                                <span className="group-hover:bg-black bg-gray-200 px-3 rounded-md group-hover:text-white inline-flex items-center">Lees verder
                                    <Icon icon="ArrowRightCircle" classes="ml-3 group-hover:text-white" />
                                </span>
                                    <div
                                        className="group-hover:text-black text-gray-400 ml-3 inline-flex items-center ml-auto leading-none pl-3 py-1 border-l-2 border-gray-200 uppercase text-xs">
                                        {tags.slice(0, 3).join(' - ')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Fade>
            )
        });
    }


    return (
        <Layout>
            <Seo seo={page.seo}/>
            <div className="bg-white">
                <div className="container">
                    {/*{renderBg()}*/}

                    <div className="py-10 text-lg">
                        <div dangerouslySetInnerHTML={{__html: page.content}}/>
                        <div className="mt-10 grid grid-cols-1">
                                    <div className="flex flex-wrap">
                                        {renderBlogs()}
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BlogOverview;

export const blogQuery = graphql`
  query BlogOverviewId($id: String!) {
    wpPage(id: { eq: $id }) {
        seo {  
          canonical
          title
          metaDesc
          focuskw
                
      }
      uri
      title
      content
    }
    allWpPost(sort: {fields: date, order: DESC}, filter: {categories: {nodes: {elemMatch: {name: {eq: "Blog"}}}}}) {
    edges {
      node {
        id
        title
        slug
        content
        date(locale: "nl", formatString: "DD MMMM, YYYY")
           tags {
          nodes {
            name
          }
        }
         featuredImage {
      node {
        id
        localFile {
              publicURL
               childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 300)
              }
            }
      }
    }
      }
    }
  }  
 }
`;
